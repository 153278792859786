import React from 'react';

import Layout from '../../layout/CompanyLayout';
import PublishProjectView from '../../views/company/PublishProjectView';
const IndexPage = (): React.ReactElement => {
  return (
    <Layout>
      <PublishProjectView isPublish={true}></PublishProjectView>
    </Layout>
  );
};

export default IndexPage;
